 @font-face {
     font-family: 'ProximaNova-Regular';
     src: url('../fonts/proxima/regular/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),  url('../fonts/proxima/regular/ProximaNova-Regular.otf')  format('opentype'),
             url('../fonts/proxima/regular/ProximaNova-Regular.woff') format('woff'), url('../fonts/proxima/regular/ProximaNova-Regular.ttf')  format('truetype'), url('../fonts/proxima/regular/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
     font-weight: normal;
     font-style: normal;
 }


@font-face {
    font-family: 'ProximaNova-Bold';
    src: url('../fonts/proxima/bold/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),  url('../fonts/proxima/bold/ProximaNova-Bold.otf')  format('opentype'),
           url('../fonts/proxima/bold/ProximaNova-Bold.woff') format('woff'), url('../fonts/proxima/bold/ProximaNova-Bold.ttf')  format('truetype'), url('../fonts/proxima/bold/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
  }