.map {
    width: 100%;
    height: 640px;

    position: relative;

    @include media('mobile'){
        height: 492px;
    }

    &__controls {
        box-sizing: border-box;
        width: 320px;
        height: 174px;
        padding: 15px 30px 20px;

        box-shadow: 0 40px 90px rgba(0,0,0,0.3);

        background-color: #fff;

        position: absolute;
        top: 75px;
        left: 5.2%;
        z-index: 100;

        &:before {
            width: 100%;
            height: 6px;

            position: absolute;
            top: 0;
            left: 0;

            background-color: #082657;

            content: '';
        }

        h3 {
            margin: 5px 0 10px;

            color: #222;
            // font-family: $defaultFont;
            font-size: 25px;
            font-weight: 400;
        }

        @include media('mobile'){
            height: 54px;
            //height: 145px;
            box-shadow: none;
            top: 0;
            left: 15px;
            padding: 12px 15px;


            transition: height 0.3s;

            h3 {
                box-sizing: border-box;
                height: 54px;
                padding: 17px 15px 12px;
                margin: -12px -15px;

                position: relative;

                color: #333;
                font-size: 16px;
                font-weight: 600;
                
                &:after {
                    width: 14px;
                    height: 7px;

                    position: absolute;
                    top: 27px;
                    right: 15px;
                    
                    background: url(../img/sprite.png) no-repeat -94px -14px;
                    
                    content: '';
                }
            }

            &.open {
                height: 151px;
                transition: height 0.3s;


                h3 {
                    &:after {
                        background-position: -94px -21px;
                    }
                }
            }
        }

        @include media-custom(600px){
            width: 100%;
            left: 0;
        }
    }

    &__links { display: none; }
}

#map {
    width: 100%;
    height: 100%;
}

/* Results block  */

.leaflet-control-geosearch.leaflet-control {
    width: 260px;
    top: 145px;
    left: calc(5.2% + 30px);

    @include media('mobile'){
        width: 290px;
        top: 54px;
        left: 30px;
        height: 0;
        overflow: hidden;

        transition: height 0.3s;

        &.open {
            height: 81px;
            transition: height 0.3s;
        }
        //display: none;

    }

    @include media-custom(600px){
        width: auto;
        left: 15px;
        right: 15px;
    }
}

#results {
    box-sizing: border-box;
    display: none;
    padding: 0;
    width: 260px;
    box-shadow: 0 40px 90px rgba(0,0,0,0.3);

    position: absolute;
    top: 181px;
    left: calc(5.2% + 30px);

    background: #fff;
    z-index: 1002;

    text-align: left;

    @include media('mobile'){
        width: 290px;
        top: 87px;
        left: 30px;
    }

    @include media-custom(600px){
        width: auto;
        left: 15px;
        right: 15px;
    }
}

#results a:link, #results a:visited {
    display:block;
    padding:10px 15px;
    border-bottom:1px solid #d3d3d3;
    text-decoration:none; color:#222;
    font-size:13px;
    margin-bottom:0px;
    font-family: 'Arial', sans-serif;
}

#results a:hover, #results a:hover.visited {
    background:#f0f0f0;
    color:#222;
}
#results a:link:last-child, #results a:visited:last-child, #results a:hover:last-child, #results a:hover.visited:last-child {border:0px;}

#results a.a_city.active {
    background:#f0f0f0;
}

#s-results {position:absolute; top: 249px; background:#ffffff; display:none; box-shadow:0px 40px 90px rgba(0,0,0,0.3); text-align:left;  z-index:1002; padding:0px; width: 320px;  left: 5.2%;
    @include media('mobile'){
        top: 151px;
        width: 320px;
        left: 15px;
    }

    @include media-custom(600px){
        width: auto;
        left: 0;
        right: 0;
    }
}
#s-results .search-info {background:#e6e6e6; padding:10px 15px; display:block;}

.s-results_item {
    display: block;
    padding: 10px 9%;
    border-top: 1px solid #d3d3d3;
    color:#313131;
    font-size:14px;
    margin-bottom:0px;
    cursor: pointer;

    &.active {
        background:#f0f0f0;
    }

    @include media('mobile'){
        top: 126px;
        width: auto;
        left: 15px;
        right: 15px;

        padding: 10px 15px;
    }
}
.s-results_item:hover,
.s-results_item.selected {
    background-color: #f0f0f0;
}

.s-results_title {
    display: inline-block;

    color: #193153;
    font-size: 14px;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-weight: 600;
}

.s-results_item:hover .s-results_title,
.s-results_item.selected .s-results_title {
    color: #5f80af;
}

.s-results_holder {
    margin-top: 6px;
}

.s-results_address {
    display: inline;

    color: #222;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 12px;
    vertical-align: top;
}

/* Popup styles */

.popup {
    box-sizing: border-box;
    width: 100%;
    padding: 17px 20px 14px;

    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    font-size: 13px;

    a {
        color: #007cc3;

        &:hover {
            text-decoration: none;
        }
    }

    &__thumb {
        float: left;
        width: 70px;
        height: 70px;

        position: relative;

        cursor: pointer;

        &:before {
            width: 100%;
            height: 100%;

            position: absolute;
            top: 0;
            left: 0;

            background-color: rgba(0 ,0 ,0 , .1);

            opacity: 0;
            transition: opacity 0.25s;
            content: '';
        }

        &:hover:before {
            opacity: 1;

            transition: opacity 0.25s;
        }

        img {
            max-width: 70px;
            max-height: 70px;
        }
    }

    &__holder {
        margin-left: 84px;
        min-height: 70px;
        margin-bottom: 10px;
    }

    &__title {
        display: block;

        color: #222;
        font-family: 'Open Sans', Arial, Helvetica, sans-serif;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
    }

    &__phones,
    &__web,
    &__email {
        display: block;

        font-size: 13px;
        line-height: 1.6;

        strong {
            font-weight: 600;
        }

        a {
            display: inline-block;

            line-height: 1;

            &:hover {
                border-color: transparent;
            }
        }
    }
}

/* Rating styles */
.map {
    .rating {
        display: block;
        width: 64px;
        height: 12px;
        margin: 3px 0 7px;

        position: relative;

        background: url(../img/map/map-rating.png) 0 0 no-repeat;

        &_type_inline {
            display: inline-block;
            margin-right: 10px;
            margin-top: 1px;

            vertical-align: top;
        }

        &__fill {
            height: 12px;

            position: absolute;
            top: 0;
            left: 0;

            background: url(../img/map/map-rating.png) 0 -12px no-repeat;

            font-size: 0;
            line-height: 0;
        }
    }
}

/* Tiny Scrollbar */

.scroll-area {
    outline:none;
    margin:0 auto 20px;
    overflow:auto;
    position:relative;
}

.scroll-area .scroll-content {
    position:relative;
}

.scroll-area .scrollbar {
    display:block;
    background-color:#111;
    position:absolute;
}

.scroll-area .scrollbar span {
    display:block;
}

.scroll-area .track {
    height:100%;
    position:relative;
}

.scroll-area .thumb {
    height:16px;
    width:16px;
    cursor:pointer;
    overflow:hidden;
    position:absolute;
    z-index:4;
}

.scroll-area .thumb span {
    background-color:#333;
    position:absolute;
}

.scroll-area .thumb:hover span,
.scroll-area:focus .thumb span {
    background-color:#3f3f3f;
}

.scroll-area .thumb:active span,
.scroll-area .thumb.hold span {
    background-color:#666;
}

.scroll-area .disable {
    display:none;
}

/* Vertical Scrollbar */
.y-axis {
    padding-right:20px;
    height:400px;
}

.y-axis.js {
    padding-right:0;
    height:auto;
}

.y-axis .scroll-inner {
    height:400px;
    padding-right:20px;
}

.y-axis .scrollbar {
    top:0;
    right:0;
    bottom:0;
    width:6px;
}

.y-axis .thumb {
    top:0;
    right:0;
    left:0;
    margin-left:-5px;
    text-align:center;
}

.y-axis .thumb span {
    width:2px;
    top:2px;
    bottom:2px;
    left:7px;
}

/* Horizontal Scrollbar */
.x-axis {padding-bottom:20px}
.x-axis.js {padding-bottom:0}

.x-axis .scroll-content {
    width:3000px;
    padding-bottom:20px;
}

.x-axis .scrollbar {
    right:0;
    bottom:0;
    left:0;
    height:6px;
}

.x-axis .thumb {
    top:0;
    bottom:0;
    left:0;
    margin-top:-5px;
}

.x-axis .thumb span {
    height:2px;
    top:7px;
    right:2px;
    left:2px;
}

/* Test! */
.scroll-area:focus {outline:1px dotted red}