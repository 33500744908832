$defaultFont: 'ProximaNova-Regular', Arial, Helvetica, sans-serif;
$titleFont: 'ProximaNova-Bold', Arial, Helvetica, sans-serif;

body {
    min-width: 360px;    
    margin: 0 auto;

    background: #fff;

    font-family: $defaultFont;

    &.is-reveal-open {
        position: relative;
    }
}

.layout {
    overflow: hidden;

    &__inner {        
        padding: 0 0;
        margin: 0 auto;

        @include breakpoint(medium){
            padding: 0 24px;
        }

        @include breakpoint(1126px){
            max-width: 1026px;
            padding: 0;
        }
    }
}

html.is-reveal-open {
    position: relative;
}

html.is-reveal-open body {
    position: relative;
}

%title {
    display: block;

    color: #333;
    font: {
        family: $titleFont;
        size: 25px;
        weight: normal;
    }
    line-height: 1.2;
    text: {
        align: center;
        transform: uppercase;
    }

    @include breakpoint(large){
        font-size: 30px;
    }
}

// %list {
//     @extend .row;
//     margin: 0 -10px;  
    
//     @include breakpoint(medium){
//         margin: 0 -15px; 
//     }
// }

%inner {
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0 auto;

    @include breakpoint(medium){
        padding: 0 24px;
    }

    @include breakpoint(1160px){
        max-width: 1060px;
        padding: 0;
    }
}

a {  
    color: #082657;    
    transition: all 0.25s;

    &:hover {        
        color: #082657;
        text-decoration: underline;

        transition: all 0.25s;
    }

    &:focus {      
        color: #082657;        
    }
}

p {
    margin: 15px 0 0;

    @include breakpoint(large){
        margin: 25px 0 0;
    }
}