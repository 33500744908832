%input {
    box-sizing: border-box;
    display: block;
    box-shadow: none;
    width: 100%;    
    color: #000;
    border: 0;
    margin-bottom: 0;
    border-bottom: 1px solid #b2a446;

    background: transparent;
    transition: all 0.25s 0.1s;

    font: {        
        size: 16px;
    }  

    &:focus {
        outline: none;
        
        transition: all 0.25s;
    } 
    
    .field--focused & {
        background: transparent;
        border: 0;
        border-bottom: 1px solid #333;
        box-shadow: none;
    }
}

.form {
    box-sizing: border-box;
    padding: 40px 30px 50px;    

    background: #ffeb64 url(../img/form/bckg.jpg) no-repeat bottom left;

    @include breakpoint(large) {
        padding: 58px 0 66px;    
    }

    &__inner {
        max-width: 584px;
        margin: 0 auto;
    }

    &__title {
        @extend %title;

        font-size: 20px;
    }

    &__message {
        display: none;
        margin-top: 7px;

        color: #e32e32;
        font-size: 13px;
        line-height: 1.1;
        text-align: left;

        .form__error & {
            display: block;
        }
    }

    &__row {
        margin-top: 38px;      

        position: relative; 

        &:first-child {
            margin-top: 45px;

            @include breakpoint(large){
                margin-top: 69px;
            }
        }

        &_type_flex {
            display: flex;
            margin: 36px -15px 0;

            @include media-custom(560px){
                margin: 36px 0 0;
                flex-wrap: wrap;
            }
        }

        &_type_submit {
            margin-top: 40px;

            text-align: center;

            @include breakpoint(large){
                margin-top: 50px;
            }
        }
    }

    &__input {
        @extend %input;
        height: 33px;
        padding: 0;
       
        transition: all 0.25s;

        .form__error & {
            position: relative;
        }

        .field--focused &,
        &:hover {
            border-color: #333;
            transition: border-color 0.25s;
        }
    }  
    
    &__cell {
        display: inline-block;
        width: calc(50% - 15px);
        margin: 0 15px;

        position: relative;

        vertical-align: top;
        
        &_type_double {
            @include media-custom(560px){
                display: block;
                width: 100%;
                margin: 0;

                &:nth-of-type(2){
                    margin-top: 36px;
                }
            }
        }
    }

    &__label {
        display: inline-block;        

        position: absolute;        
        top: 0;
        left: 0;

        color: #333;
        font: {
            family: $titleFont;
            size: 14px;            
        }    
        text-transform: uppercase;
        line-height: 1;

        transition: all 0.25s 0.1s;

        input:focus + &,
        .field--focused &,
        .field--value & {
            top: -15px;
            // opacity: 0.5;

            // font-size: 12px;
            transition: all 0.25s;

            &:after {
                // font-size: 12px;
                color: #000;

                transition: all 0.25s;
            }
        }        

        .field--value & {   
            .focused & {
                &:after {
                    color: #000;
                }  
            }     
        }

        &_type_required {
            &:after {
                display: inline-block;
                margin-left: 3px;

                color: #333;
                font-size: 16px;

                content: '*';
                transition: color 0.25s 0.1s;                
            }
        }
        
        @include media('mobile'){
            top: 6px;

            font-size: 14px;           
        }          
    }

    &__textarea {
        @extend %input; 
        width: 100%;
        overflow: hidden;       
        padding: 0;
        height: 33px;
        min-height: 33px;
        resize: none;
        line-height: 1.5;

        .field--focused &,
        &:hover {
            border-color: #333;
            transition: border-color 0.25s;
        }
    }

    &__sent {
        display: none;
    }

    &__note {
        display: block;
        margin-top: 44px;

        color: #333;
        font-size: 16px;
        line-height: 1.625;
    }
}