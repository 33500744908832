.button {
    box-sizing: border-box;
    display: inline-block;
    border: 1px solid #333;;
    height: 45px;
    padding: 14px 50px;
    margin-bottom: 0;
    
    background: #fff;
    font: {
        size: 14px;
        weight: 700;
    }
    text-transform: uppercase;
    transition: all 0.25s;
    cursor: pointer;

    color: #333;    
    text-decoration: none;
    vertical-align: middle;

    &:active,
    &:focus {
        background: #ffe63e;
        text-decoration: none;  
        
        color: #333;

        transition: all 0.25s;
    }

    &:hover {
        background: #fff;

        color: #333;

        text-decoration: none;
    }

    @include breakpoint(large){
        &:hover,
        &:focus {
            background: #ffe63e;
            text-decoration: none;  
            
            color: #333;
    
            transition: all 0.25s;
        }
    }

    &__holder {
        margin-top: 30px;

        text-align: center;
    }

    .promo__buttons & {
        width: 100%;
        height: 53px;        

        font-size: 18px;
        padding: 17px 50px 16px;  
        
        &:first-child {            
            margin-bottom: 15px;
        }

        @include breakpoint(638px){
            width: 254px;

            &:first-child {
                margin-right: 20px;
                margin-bottom: 0;
            }
        }

        @include breakpoint(large){
            height: 63px;
            padding: 20px 50px; 
        }
    }
}