.footer {
    // margin-top: 45px;
    padding: 40px 0 45px;

    position: relative;

    text-align: center;
    color: #333;
    font-size: 12px;  
    
    @include breakpoint(large){
        margin-top: 78px;
        padding: 70px 0 115px;

        &:before {
            width: 236px;
            height: 1px;
    
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
    
            background: #d0d0d0;
    
            content: '';
        }    
    }
    
    &__inner {
        @extend %inner;
    }  
    
    &__copyright {
        line-height: 1.67;
    }
    
    &__dev {
        margin-top: 10px;

        img {
            margin-top: -5px;
        }
    }
}