.wholesale {
    padding: 40px 0 40px;

    @include breakpoint(large){
        padding: 80px 0 60px;
    }

    &__inner {
        @extend %inner;
    }

    &__title {
        @extend %title;
    }

    &__description {
        padding-top: 30px;
        margin-top: 28px;

        position: relative;

        color: #333;
        font-size: 16px;
        line-height: 1.625;
        text-align: center;

        @include breakpoint(large){
            margin-top: 35px;
        }

        &:before {
            width: 80px;
            height: 5px;
            margin-left: -40px;

            background: #ffe63e;

            position: absolute;
            top: 0;
            left: 50%;

            content: '';
        }
    }
}