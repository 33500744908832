@mixin media($device: 'desktop') {

    @if $device == 'desktop' {
        @media screen and (max-width: 1299px) {
            @content;
        }
    }

    @if $device == 'mobile' {
        @media screen and (max-width: 999px) {
            @content;
        }
    }
}

@mixin media-custom($max-width) {
    @media screen and (max-width: $max-width) {
        @content;
    }
}