.promo {
    padding: 20px 0;

    background: url(../img/promo/bckg.jpg) no-repeat;
    background-size: cover;

    @include breakpoint(medium){
        padding: 40px 0;
    }

    @include breakpoint(large){
        height: 100vh;
        min-height: 650px;
        padding: 0;
    }

    &__inner {
        @extend %inner;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__holder {
        box-sizing: border-box;
        width: 100%;
        height: auto;
        padding: 13px;       
        border: 2px solid #fff;

        @include breakpoint(large){            
            height: 686px; 
        }
    }

    &__content {
        box-sizing: border-box;
        width: 100%;        
        padding: 30px 20px;

        background: #fff url(../img/promo/headimg.jpg) no-repeat right bottom;

        text-align: center;

        @include breakpoint(large){
            padding: 130px 0 0;
            height: 100%;
        }
    }

    &__logo {
        display: inline-block;
        max-width: 70px;

        @include breakpoint(large){
            max-width: none;
        }
    }

    &__title {
        display: block;
        margin: 25px 0 0;

        color: #333;
        font: {
            family: $titleFont;
            size: 28px;
            weight: normal;
        }
        text-transform: uppercase;
        line-height: 1.2;    
        
        @include breakpoint(large){
            margin: 40px 0 0;

            font-size: 40px;
        }
    }

    &__about {
        max-width: 650px;
        margin: 15px auto 0;

        color: #333;
        font-size: 16px;
        line-height: 1.5;

        @include breakpoint(large){
            margin: 20px auto 0;

            line-height: 1.625;
        }
    }

    &__buttons {
        margin-top: 20px;

        font-size: 0;

        @include breakpoint(large){
            margin-top: 45px;
        }
    }
}

@media screen and (max-height: 700px) and (min-width: 1000px){
    .promo {   
        &__holder {
            height: auto;
        }

        &__content {
            padding-top: 50px;
            padding-bottom: 70px;
        }
    }
}